import React, {useState} from 'react';
import {Alert, Button} from "react-bootstrap";

const AlertContext = React.createContext(null);
AlertContext.displayName = 'AlertContext';

export const AlertStatus = {
    None: 'NONE',
    Error: 'danger',
    Success: 'success'
}

const AlertProvider = ({ children }) => {
    const [alertType, setAlertType] = useState(AlertStatus.None);
    const [alertText, setAlertText] = useState("");
    const [alertTitle, setAlertTitle] = useState("");

    return (
        <AlertContext.Provider
            value={{
                alert,
                alertText: alertText,
                success: (text, timeout) => {
                    setAlertText(text);
                    setAlertType(AlertStatus.Success);
                    setAlertTitle("Success");
                    setTimeout(() => {
                        setAlertType(AlertStatus.None);
                    }, timeout * 1000 || 10000)
                },
                error: (text, timeout) => {
                    setAlertText(text);
                    setAlertType(AlertStatus.Error);
                    setAlertTitle("Error");
                    setTimeout(() => {
                        setAlertType(AlertStatus.None);
                    }, timeout * 1000 || 10000)
                },
                clear: () => (setAlertType(AlertStatus.None)),
            }}
        >
            {children}
            {alertType !== AlertStatus.None &&
                <Alert className="alert" variant={alertType}>
                    <Alert.Heading>{alertTitle}</Alert.Heading>
                    <hr />
                    <p>{alertText}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setAlertType(AlertStatus.None)} variant={`outline-${alertType}`}>
                            Close
                        </Button>
                    </div>
                </Alert>
            }
        </AlertContext.Provider>
    );
};

export { AlertProvider };
export default AlertContext;
