import {Col, Form, Row} from "react-bootstrap";
import {MittelModal} from "./Modal.js";
import React from "react";
import {APIClient} from "../utils/APIClient.js";
import useForm from "../hooks/useForm.js";
import {fetchPromises} from "../utils/fetchPromises.js";

function RetailerModal({setModal, retailer, refreshRetailers, countries}) {
    const {form, onInputChange} = useForm({
        countryId: countries && countries[0].id,
        ...retailer
    });

    const onModalSaveClick = async () => {
        try {

            await APIClient().saveRetailer({
                ...form
            });
            refreshRetailers();
        } catch (e) {
            console.log(e);
        }
    }

    const onModalClose = () => {
        setModal({
            show: false
        });
    }

    return (
        <MittelModal show onClose={onModalClose} onCallback={onModalSaveClick} title="Retailer">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Name
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" onChange={onInputChange} value={form.name || ''} required />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Country
                </Form.Label>
                <Col sm="8">
                    <Form.Select name="countryId" onChange={onInputChange} value={form.countryId}>
                        {countries && countries.map((c, index) =>
                            <option key={index} value={c.id}>{c.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Form.Group>
        </MittelModal>
    );
}

export default fetchPromises({
    retailer: (props) => props.retailerId && ({ name: 'getRetailer', props: ['retailerId'] }),
    countries: () => ({ name: 'getCountries' })
}, false)(RetailerModal);