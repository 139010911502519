import React from 'react';
import get from 'lodash/get';
import {APIClient} from "./APIClient";

export function fetchPromises(promises, showLoading = true) {
    return function(Cmp) {
        class FetchPromises extends React.Component {
            mounted = true;

            constructor(props) {
                super(props);

                // Init promises states
                this.state = {
                    loading: true,
                    cmpKey: 0,
                    error: false,
                };
                Object.keys(promises).forEach(pKey => this.state[pKey] = undefined);
            }

            componentDidMount() {
                this.fetchPromisesToState();
            }

            componentWillUnmount() {
                this.mounted = false;
            }

            componentDidUpdate(prevProps) {
                let promisePropsChanged = false;
                Object.keys(promises).forEach(pKey => {
                    const promise = promises[pKey](this.props);
                    const promiseProps = promise && promise.props;
                    promiseProps && promiseProps.forEach(propName => {
                        if (get(this.props, propName) !== get(prevProps, propName)) {
                            promisePropsChanged = true;
                        }
                    });
                });

                if (promisePropsChanged) {
                    this.fetchPromisesToState(false);
                }
            }

            fetchPromisesToState(_showLoading = true) {
                this.setState({
                    loading: _showLoading
                });

                let numOfPromises = Object.keys(promises).length;

                Object.keys(promises).forEach(pKey => {
                    const promise = promises[pKey](this.props);

                    if (promise) {
                        const promiseName = promise && promise.name;
                        const promiseProps = promise && promise.props;
                        const arrayProps = (promiseProps && promiseProps.map(propName => get(this.props, propName))) || [];

                        if(!APIClient()[promiseName]) return null;

                        promiseName && Promise.resolve(APIClient()[promiseName].apply(null, arrayProps)).then((data) => {
                            if (this.mounted) { // don't call promise callback if component is already unmounted
                                let state;

                                if (data) {
                                    state = {
                                        [pKey]: data
                                    };
                                }
                                numOfPromises--;

                                this.setState({
                                    ...state,
                                    loading: numOfPromises !== 0,
                                    cmpKey: this.state.cmpKey + 1
                                });
                            }
                        }).catch(e => {
                            this.setState({
                                loading: false,
                                error: e.message
                            });
                        });
                    } else {
                        numOfPromises--;
                        this.setState({
                            loading: numOfPromises !== 0
                        });
                    }

                });
            }

            render() {
                if (this.state.loading) {
                    return showLoading ? (
                        <div className="spinner pr">
                            <div className="spinner-border" role="status" />
                        </div>
                    ) : null;
                }

                const promisesValues = {};
                Object.keys(promises).forEach(pKey => {
                    promisesValues[pKey] = this.state[pKey];
                });

                return (
                    this.state.error ?
                        <span>{this.state.error}</span>
                        :
                        <Cmp
                            key={this.state.cmpKey}
                            {...promisesValues}
                            {...this.props}
                            refresh={() => this.fetchPromisesToState(true)}
                        />
                );
            }
        }

        return FetchPromises;
    };
}