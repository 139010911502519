import {Button, Table} from "react-bootstrap";
import trashIcon from "../../styles/assets/icons/trash.svg";
import editIcon from "../../styles/assets/icons/edit.svg";
import addIcon from "../../styles/assets/icons/add.svg";
import {useContext, useState} from "react";
import {fetchPromises} from "../../utils/fetchPromises.js";
import {APIClient} from "../../utils/APIClient.js";
import {ConfirmModalContext} from "../../components/ConfirmModalProvider.js";
import RetailerModal from "../../components/RetailerModal.js";

function RetailersPage({retailers, refresh}) {
    const [modal, setModal] = useState({
        show: false
    });
    const {showConfirmModal} = useContext(ConfirmModalContext);

    const onDeleteClick = async (event) => {
        const id = +event.currentTarget.dataset.id;
        showConfirmModal("Are you sure to delete retailer?", async () => {
            try {
                await APIClient().deleteRetailer(id);
                refresh();
            } catch (e) {}
        });
    }

    const onAddEditClick = async (event) => {
        setModal({
            show: true,
            retailerId: +event.currentTarget.dataset.id
        });
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Retailers</div>
                <div className="page-header-actions">
                    <Button className="d-flex align-items-center" size="sm" onClick={onAddEditClick}>
                        <img className="me-1" src={addIcon} alt="add" />
                        <span>Add New</span>
                    </Button>
                </div>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th className="text-end">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {retailers && retailers.map((r, index) =>
                            <tr key={index}>
                                <td>{r.name}</td>
                                <td>{r.countryName}</td>
                                <td className="text-end table-actions">
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="primary"
                                        onClick={onAddEditClick}
                                        data-id={r.id}
                                    >
                                        <img src={editIcon} alt="edit" className="d-block" />
                                    </Button>
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="danger"
                                        onClick={onDeleteClick}
                                        data-id={r.id}
                                    >
                                        <img src={trashIcon} alt="delete" className="d-block" />
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {modal.show &&
                <RetailerModal
                    retailerId={modal.retailerId}
                    setModal={setModal}
                    refreshRetailers={refresh}
                />
            }
        </div>
    );
}
export default fetchPromises({
    retailers: () => ({ name: 'getRetailers' })
})(RetailersPage);
