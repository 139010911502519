import {withRouter} from "react-router-dom";
import {ENUM} from "../utils/enum";
import UserBrandsMenu from "./UserBrandMenu";
import {useContext} from "react";
import {UserContext} from "../contexts/userContext.js";

function Menu({ location, history, setShowMenu }) {
    const paths = location.pathname.split('/');
    const user = useContext(UserContext);

    const onClick = (event) => {
        const selectedPath = event.currentTarget.dataset.to;
        if (paths[2] !== selectedPath) {
            history.push(selectedPath);
            setShowMenu(false);
        }
    };

    return (
        <>
            {paths[1] === ENUM.route.admin &&
                <>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.articles}`} className={paths[2] === ENUM.route.articles.substr(1) ? 'selected' : ''}>Articles</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.orders}`} className={paths[2] === ENUM.route.orders.substr(1) ? 'selected' : ''}>Orders</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.users}`} className={paths[2] === ENUM.route.users.substr(1) ? 'selected' : ''}>Users</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.brands}`} className={paths[2] === ENUM.route.brands.substr(1) ? 'selected' : ''}>Brands</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.countries}`} className={paths[2] === ENUM.route.countries.substr(1) ? 'selected' : ''}>Countries</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.importExport}`} className={paths[2] === ENUM.route.countries.substr(1) ? 'selected' : ''}>Import / Export</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.retailers}`} className={paths[2] === ENUM.route.retailers.substr(1) ? 'selected' : ''}>Retailers</li>

                </>
            }
            {(`/${paths[1]}` === ENUM.route.brand || `/${paths[1]}` === ENUM.route.scannersPrices) &&
                <UserBrandsMenu userId={user && user.id} location={location} selectedBrandId={paths[2]} onClick={onClick} />
            }
        </>
    );
}
export default withRouter(Menu);
