import React from 'react';
import ReactDOM from 'react-dom';
import './client/styles/index.scss';
import OrdersApp from './client/OrdersApp';
import {HashRouter} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <HashRouter>
          <OrdersApp />
      </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
