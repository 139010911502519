import {Form, Table} from "react-bootstrap";
import {useCallback, useEffect, useState} from "react";
import {fetchPromises} from "../../utils/fetchPromises.js";
import alertIcon from "../../styles/assets/icons/alert.svg";
import exportIcon from "../../styles/assets/icons/export.svg";
import Select from "react-select";
import {APIClient} from "../../utils/APIClient.js";
import {debounce} from "lodash";
import moment from "moment";

function ScannersPricesPage(props) {
    const [scannersPrices, setScannersPrices] = useState(props.scannersPrices);
    const [retailersFilter, setRetailersFilter] = useState(props.scannersPrices.retailers.map(country => country.options).flat());
    const [articleNameFilter, setArticleNameFilter] = useState();

    useEffect(() => {
        setScannersPrices(props.scannersPrices);
    }, [props.scannersPrices]);

    const onRetailersFilterChange = async (selectedRetailers) => {
        const selectedRetailerIds = selectedRetailers.map(r => r.value);
        const scanners = await APIClient().getScannersPrices(
            props.match.params.brandId,
            selectedRetailerIds,
            articleNameFilter,
        );

        setRetailersFilter(selectedRetailers);
        setScannersPrices(scanners)
    }

    const onArticleNameFilterChange = async (e) => {
        const articleName = e.target.value;

        setArticleNameFilter(articleName);
        debounceOnArticleNameFilterChange(articleName);
    }

    const debounceOnArticleNameFilterChange = useCallback(debounce(async (articleName) => {
        const selectedRetailerIds = retailersFilter.map(r => r.value);
        const scanners = await APIClient().getScannersPrices(
            props.match.params.brandId,
            selectedRetailerIds,
            articleName,
        );
        setScannersPrices(scanners)
    }, 200), []);

    return (
        <div className="w-100 d-flex flex-column overflow-hidden">
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                        <tr key="article">
                            <th>Article</th>
                            <th colSpan={(retailersFilter && retailersFilter.length) || 0} className="border-left">
                                <div className="d-flex justify-content-between align-items-center">
                                    Retailers
                                    <Select
                                        isMulti
                                        options={props.scannersPrices.retailers}
                                        defaultValue={retailersFilter}
                                        onChange={onRetailersFilterChange}
                                        className="font-weight-normal"
                                    />
                                </div>
                            </th>
                            <th className="border-left color-orange">AVL</th>
                        </tr>
                        <tr key="retailers">
                            <th className="secondary-title color-blue">
                                <Form.Control size="sm" name="articleName" value={articleNameFilter} onChange={onArticleNameFilterChange} />
                            </th>
                            {retailersFilter && retailersFilter.map((r, index) =>
                                <th key={index} className="secondary-title color-blue border-left">{r.label}</th>
                            )}
                            <th className="secondary-title color-blue border-left color-orange"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {scannersPrices.scanners && Object.values(scannersPrices.scanners).map((sp, index) =>
                            <tr key={index}>
                                <td>{sp.articleName}</td>
                                {retailersFilter && retailersFilter.map((r) => {
                                    const id = r.value;
                                    const retailerPrice = sp.retailersPrice[id];
                                    return retailerPrice ? 
                                        <td key={id} title={moment(retailerPrice.time).format("DD.MM.YYYY HH:mm:ss")}>
                                            {retailerPrice.price !== -1 ? 
                                                <div className="d-flex justify-content-between">
                                                    <a href={retailerPrice.url} target="_blank" rel="noreferrer">
                                                        {retailerPrice.price.toFixed(2)}
                                                    </a>
                                                    <a 
                                                        href={'/api/file/export?file=scannerHistoryPrices&scannerId=' + id} 
                                                        target="_blank"
                                                    >
                                                        <img src={exportIcon} alt="" className="me-1" />
                                                    </a>
                                                </div>
                                                :
                                                <a href={retailerPrice.url} target="_blank" rel="noreferrer">
                                                    <img src={alertIcon} alt="" className="me-1" />
                                                </a>
                                            }
                                        </td>
                                        :
                                        <td key={id}>/</td>
                                })}
                                <td className="color-orange">
                                    {sp.sumPrices && sp.articleVolumeNetMl ? (sp.sumPrices / sp.numberOfRetailersPrices / sp.articleVolumeNetMl * 1000).toFixed(2) : '/'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}
export default fetchPromises({
    scannersPrices: () => ({ name: 'getScannersPrices', props: ['match.params.brandId'] })
})(ScannersPricesPage);
