import {fetchPromises} from "../utils/fetchPromises";

function UserBrandsMenu({ brands, onClick, selectedBrandId, location }) {
    const currentPath = location.pathname.split("/")[1];
    return (
        <>
            {brands.map((b, index) =>
                <li
                    key={index}
                    onClick={onClick}
                    data-to={`/${currentPath}/${b.id}`}
                    className={b.id === +selectedBrandId ? 'selected' : ''}
                >
                    {b.name}
                </li>
            )}
        </>
    );
}
export default fetchPromises({
    brands: () => ({ name: 'getBrandsByUserId' , props: ['userId']})
})(UserBrandsMenu);
